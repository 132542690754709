import { useState } from "react";
import Logo from "./icon.svg";
import "./App.css";

function App() {
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <header>
        <nav>
          <div className="logo">
            <img src={Logo} alt="" />
            <p>Divest</p>
          </div>
          <div>
            <p className="i">Made with ❤️ from Africa</p>
          </div>
        </nav>

        <section>
          <div className="section_info">
            <h1>
              Welcome to Divest: <br /> Instant Loans, Instant Relief
            </h1>
            <p>
              At Divest, we understand that life's unexpected moments can often
              lead to financial stress. That's why we're here to offer you a
              helping hand, right when you need it most. Say goodbye to long
              waiting times and complex procedures. With Divest, you're just
              moments away from securing the funds you need.
            </p>
            <div className="input_container">
              <input
                placeholder="Enter your email address"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  if (!email) {
                    alert("Please enter your email address");
                    return;
                  }
                  if (!email.includes("@")) {
                    alert("Please enter a valid email address");
                    return;
                  }
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                    alert(
                      "Your request has been received, you will get an email with next steps in 1 to 3 business days"
                    );
                  }, 3000);
                }}
                disabled={loading}
              >
                {loading ? "Processing..." : "Request Loan"}
              </button>
            </div>
          </div>

          <div className="section_image">
            <img
              src="https://images.unsplash.com/photo-1580048915913-4f8f5cb481c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1287&q=80"
              alt="different currencies"
            />
          </div>
        </section>
      </header>

      <footer>
        <p>
          *Disclaimer: Loans are subject to approval. Terms and conditions
          apply. Divest is a registered trademark of Divest Financial Services,
          LLC.*
        </p>
      </footer>
    </>
  );
}

export default App;
